@import "aos/dist/aos.css";
@import "@wazoe/elements/styles/elements";

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply font-text;
  overflow-x: hidden;
}

.prose > p {
  margin: 0;
}

.prose > ol {
  margin: 0;
}

.prose > ul {
  margin: 0;
}
@layer components {  body {    @apply font-normal text-[15px] text-black  }  .container-default {    @apply mx-auto max-w-7xl px-6 lg:px-0  }  .form-button, .button-primary {   @apply rounded-full shadow bg-[#F39519] hover:bg-orange-400 text-white py-2 font-bold flex items-center px-8 w-auto !important;  }  .contact-form label {    @apply text-white !important;  }      .swiper-slide-next  .slider-klant-block {     @apply lg:-translate-y-3  }  .prose {   @apply text-[15px] font-light  }  .slider-klant-block {    color: white !important;    height: 350px;  }  .swiper-slide:nth-child(3n + 1) .slider-klant-block {    background: #FCC505;    box-shadow: 0px 4px 20 -10px #FDC506;  }  .swiper-slide:nth-child(3n + 2) .slider-klant-block {    background: #2E4191;    box-shadow: 0px 4px 20 -10px #2E4191;  } .swiper-slide:nth-child(3n + 3) .slider-klant-block {background: #F39519;box-shadow: 0px 4px 20 -10px #F39519;  }}